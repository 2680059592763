<template>
    <div class="account">
      <CRow>
        <CCol sm>
          <CCard style="width: 100%">
            <CCardBody>
              <CRow>
                 <CCol>
                  <h4 style="margin-bottom: 20px">
                    {{ $t("title_creation_visuel_block") }}
                  </h4>
                  <p>
                    {{ $t("line1_creation_visuel_block") }}
                  </p>
                  <p>
                    {{ $t("line2_creation_visuel_block") }}
                  </p>
                  <div style="text-align: center; margin-top: 40px">
                    <a :href="t99" target="_blank">
                    <button class="btn btn-primary">
                      {{ $t("order_creation_visuel") }}
                    </button>
                    </a>
                  </div>
                </CCol>
                <CCol style="text-align:center;"><img src="../assets/creation-graphique.png" style="width:100%;max-width:380px;" /></CCol>
               
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
  
      
      <!--router-link to="/dashboard/about">About</router-link-->
    </div>
  </template>
  
  <script>
  //import PaymentMethod from "../components/account/PaymentMethod.vue";
  
  export default {
    name: "Agency",
    /*components: {
      PaymentMethod,
    },*/
    computed: {
      t99: function(){
        return 'https://www.trucker360.agency/user/login?l='+this.$i18n.locale
      }
    },
  };
  </script>
  
  <style scoped>
  h6,
  h4 {
    color: #f45a2a;
    font-weight: bold;
  }
  
  
  
  li {
    font-size: 16px;
    padding-bottom: 8px;
    padding-top: 8px;
  }
  
  
  td{
    padding-bottom:5px;
    padding-top: 5px;
  }
  
  
  b{
    color:#f45a2a;
  }
  
  
  
  </style>
  